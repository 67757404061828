import React from 'react';
import { Route } from 'react-router-dom';

import "./BootstrapTheme-1.scss";
import './CustomStyle.scss';

const ErrorLayout = ({ children }) => (
    <React.Fragment>
      {children}
    </React.Fragment>
  );

  const ErrorLayoutRoute = ({component: Component, ...rest}) => {
    return (
      <Route {...rest} render={matchProps => (
        <ErrorLayout>
            <Component {...matchProps} />
        </ErrorLayout>
      )} />
    )
  };

export default ErrorLayoutRoute;